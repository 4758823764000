import React, { useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import ReactSignatureCanvas from "react-signature-canvas";
import { Formik, FormikProps, Form } from "formik";
import debounce from "lodash/debounce";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import Button, { ButtonThemes } from "../../../components/button/button";
import LabeledInput, { LabelModes } from "../../../components/molecules/labeled-inputs/labeledInput";
import {
  SubmitPodRequest,
  SubmitPodModelRequestSchema,
  ConsigneeDetails,
  ProbillDetails,
  StopDetails
} from "../../../app/data/stop-details/models";
import { updateStopsStatuses } from "../../../app/common/statusUpdater";
import XGSFormInput from "../../../components/form/input/xgsFormInput";
import XGSFormTextarea from "../../../components/form/textarea/xgsFormTextarea";
import { useAppDispatch } from "../../../hooks/storeHooks";
import {
  deliverShipmentSelector,
  submitPod,
} from "../../../slices/stop-details/deliverShipmentSlice";
import { ROUTES } from "../../../app/route/RoutesConfig";
import { StopDetailsPath } from "../routes";
import { getRouteSummary } from "../../../slices/route-summary/routeSummarySlice";
import { getRouteMapData } from "../../../slices/route-map/routeMapSlice";
import { getAllStopsDetails } from "../../../slices/stop-details/stopDetailsSlice";
import { StopStatus } from "../../../app/data/common/constants";
import infoIcon from "../../../images/info-circle_blue.svg";
import "./signShipment.scss";

interface Props {
  manifestNumber: number;
  show: boolean;
  consignee: ConsigneeDetails;
  isAgentHandOff: boolean;
  onCloseModal: () => void;
};

const emailFields = [
  "firstEmailRecipient",
  "secondEmailRecipient",
  "thirdEmailRecipient"
];

const initialFormValues: SubmitPodRequest = {
  signer: "",
  signature: "",
  notes: "",
  firstEmailRecipient: "",
  secondEmailRecipient: "",
  thirdEmailRecipient: "",
  deliveryTime: "",
  longitude: 0,
  latitude: 0
};

dayjs.extend(utc);

export const SignShipment: React.FC<Props> = ({
  manifestNumber,
  show,
  consignee,
  isAgentHandOff,
  onCloseModal,
}) => {
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const padRef = useRef<ReactSignatureCanvas | null>(null);
  const formRef = useRef<FormikProps<SubmitPodRequest>>(null);
  const deliverShipmentState = useSelector(deliverShipmentSelector);
  const [showCanvas, setShowCanvas] = useState<boolean>(false);
  const [signatureTouched, setSignatureTouched] = useState(false);
  const [signatureError, setSignatureError] = useState("");
  const [emailCount, setEmailCount] = useState(1);
  const [longitude, setLongitude] = useState(0);
  const [latitude, setLatitude] = useState(0);

  const stopNumber = useMemo(() => {
    const stopParam = params[StopDetailsPath.stopNumber];
    if (stopParam) return parseInt(stopParam, 10);
    if (!stopParam) navigate(`/${manifestNumber}${ROUTES.route}`);
  }, [params, navigate, manifestNumber]) as number;

  useEffect(() => {
    // Canvas does not render properly without delaying
    const timeout = setTimeout(() => {
      setShowCanvas(true);
    }, 500);
    return () => {
      clearTimeout(timeout);
      setShowCanvas(false);
    };
  }, []);

  const onClear = () => {
    padRef.current?.clear();
    formRef.current?.setValues(initialFormValues);
    setEmailCount(1);
    setSignatureTouched(false);
    validateSignature();
  };

  const isSignatureValid = () => {
    const data = padRef.current?.getTrimmedCanvas().toDataURL();
    if ( !data || data?.length < 5000 || data?.length > 40000) {
      return false;
    }
    return true;
  }

  const validateSignature = () => {
    if (!isSignatureValid()) {
      setSignatureError("Signature is required");
    } else {
      setSignatureError("")
    }
  }

  const updateCache = async () => {
    const cache = await caches.open("xgs-driver-app-api-responses");
    const cachedStopsResponse = await cache.match(process.env.REACT_APP_API_BASE_URL + `/drivers/routes/${manifestNumber}/stops`);
    let stopsResponseData: StopDetails[];
    if (cachedStopsResponse) {
      stopsResponseData = JSON.parse(await cachedStopsResponse.text());
    } else return;

    const stopIndex = stopsResponseData.findIndex((stop: StopDetails) => stop.order === stopNumber);
    const consigneeIndex = stopsResponseData[stopIndex].consignees.findIndex((consigneeObj: any) => consigneeObj.probills.find((probillObj: ProbillDetails) => probillObj.probill === consignee.probills[0].probill));

    stopsResponseData[stopIndex].consignees[consigneeIndex].delivered = true;

    await cache.put(process.env.REACT_APP_API_BASE_URL + `/drivers/routes/${manifestNumber}/stops`, new Response(
      JSON.stringify(stopsResponseData), {
        headers: {
          "Content-Type": "application/json"
        }
      }
    ));

    // Update Stops Statuses
    await updateStopsStatuses(manifestNumber, stopNumber);
  };

  const addEmail = () => {
    setEmailCount(emailCount + 1);
  };

  const onSubmit = (values: SubmitPodRequest) => {
    const data = padRef.current?.getTrimmedCanvas().toDataURL();
    if (!isSignatureValid()) return setSignatureError("Signature is required");;
    if (data) {
      values = {
        ...values,
        signature: data,
        deliveryTime: dayjs.utc().format(),
        longitude,
        latitude
      };
    }
    values.probills = consignee.probills
      .filter(probill => 
        !probill.exception?.refused &&
        !probill.itemDetails.every(item => item.exception?.refused))
      .map(probill => probill.probill);
    values.stop = stopNumber;
    setSignatureError("");
    dispatch(submitPod(manifestNumber, values,
      (response) => {
        toast.info(navigator.onLine ? "The POD has been saved!" : "The POD will be saved when Internet connection is restored!");
        if (!navigator.onLine) {
          updateCache();
          dispatch(getRouteSummary(manifestNumber));
          dispatch(getAllStopsDetails(manifestNumber));
          dispatch(getRouteMapData(manifestNumber));
          onCloseModal();
          if (response.status === StopStatus.COMPLETED) navigate(`/${manifestNumber}${ROUTES.route}`);
        } else {
          dispatch(getRouteSummary(manifestNumber));
          dispatch(getAllStopsDetails(manifestNumber));
          dispatch(getRouteMapData(manifestNumber));
          onCloseModal();
          if (response.status === StopStatus.COMPLETED) navigate(`/${manifestNumber}${ROUTES.route}`);
        }
      }, () => {
        toast.error("Something went wrong");
      }));
  };

  useEffect(() => {
    navigator.geolocation.getCurrentPosition((position) => {
      position?.coords?.longitude && setLongitude(position.coords.longitude);
      position?.coords?.longitude && setLatitude(position.coords.latitude);
    });
  }, []);

  return (
    <div className="xgs-sign-shipment">
      <Formik
        innerRef={formRef}
        initialValues={initialFormValues}
        onSubmit={onSubmit}
        validationSchema={SubmitPodModelRequestSchema}
      >
        {(props: FormikProps<SubmitPodRequest>) => (
          <Form>
            <div className="xgs-sign-shipment__field">
              <LabeledInput required error={signatureError} label="Signature:" labelMode={LabelModes.column}>
                <div
                  className={`xgs-sign-shipment__sign-pad ${
                    signatureError ? "xgs-sign-shipment__sign-pad--error" : ""
                  }`}
                >
                  {!signatureTouched && <div className="xgs-sign-shipment__sign-pad__placeholder">Sign here</div>}
                  {showCanvas && (
                    <ReactSignatureCanvas
                      clearOnResize={false}
                      backgroundColor="white"
                      onBegin={() => setSignatureTouched(true)}
                      onEnd={debounce(validateSignature, 800)}
                      canvasProps={{
                        className: "xgs-sign-shipment__sign-pad__canvas",
                      }}
                      ref={(canvasRef) => (padRef.current = canvasRef)}
                    />
                    )}
                </div>
              </LabeledInput>
              {signatureError && (
                <div className="xgs-labeled-input__validation-error">
                  {signatureError}
                </div>
              )}
            </div>
            <XGSFormInput
              required
              maxLength={40}
              onChange={(e) =>
                props.setFieldValue("signer", e.currentTarget.value)
              }
              name="signer"
              label="Printed Name:"
              className="xgs-sign-shipment__field"
              labelMode={LabelModes.column}
            />
            <XGSFormTextarea
              counter={200}
              maxLength={200}
              name="notes"
              className="xgs-sign-shipment__notes"
              label="Notes:"
            />
            
            {!isAgentHandOff && <>
              {[...Array(emailCount)].map((e, i) =>
                <React.Fragment key={`email-field-${i}`}>
                  <XGSFormInput
                    maxLength={60}
                    name={emailFields[i]}
                    label={`Send POD by email ${emailCount > 1 ? `(${i + 1})` : ""}:`}
                    className="xgs-sign-shipment__field"
                    labelMode={LabelModes.column}
                    placeholder="name@domain.com"
                  />
                </React.Fragment>
              )}

              {emailCount < 3 && (
                <div
                  className="xgs-sign-shipment__add-email"
                  onClick={addEmail}
                >+ Add email</div>
              )}

              <div className="xgs-notification-block">
                <img src={infoIcon} alt="Information" />
                <div className="xgs-notification-block__text">
                  There may be a 5-15 minute delay before the email is sent.
                </div>
              </div>
            </>
            }

            <div className="xgs-sign-shipment__buttons">
              <Button onClick={() => {
                validateSignature();
                props.submitForm();
              }} 
                spinner={deliverShipmentState.requestStarted} type="button" theme={ButtonThemes.blue}
                disabled={!!props.errors.signer || !!signatureError}
              >
                Submit
              </Button>
              <Button type="button" onClick={onClear} theme={ButtonThemes.gray}>
                Clear
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};
