import api, { ApiResponse } from "./api";

const handleError = async (response: ApiResponse<any>) => {
  if (response.ok()) {
    return response;
  } else {
    let error = response.getError?.() as any;
    error = !!error ? (typeof error === 'string' ? error : error['message']) : "Something went wrong";
    throw error;
  }
}

export const apiQueryAdapter = {
  query: async<T> (
    url: string,
    method: 'GET' | 'POST' | 'PUT' | 'DELETE',
    otherArgs: {
      params?: Record<string, any>;
      body?: Record<string, any>;
    } = {}
  ) => {
    const { params, body } = otherArgs;
    let response;
    
    switch (method) {
      case 'GET':
        response = handleError(await api.get(url, { params }));
        break;
      case 'POST':
        response = handleError(await api.post(url, body, { params }));
        break;
      case 'PUT':
        response = handleError(await api.put(url, body, { params }));
        break;
      case 'DELETE':
        response = handleError(await api.delete(url, { params, data: body }));
        break;
      default:
        throw new Error(`Unsupported HTTP method: ${method}`);
      }
    return (await response).data as unknown as T | undefined;
  },
};