import React, { useState } from "react";
import SlideInModal from "../../components/slide-in-modal/slideInModal";
import Button, { ButtonThemes } from "../../components/button/button";
import "./completePickupModal.scss";
import { toast } from "react-toastify";
import { updateCacheForCompletedPickup } from "../../app/common/statusUpdater";
import { getRouteSummary } from "../../slices/route-summary/routeSummarySlice";
import { useAppDispatch } from "../../hooks/storeHooks";
import { getAllStopsDetails, stopDetailsSelector } from "../../slices/stop-details/stopDetailsSlice";
import { getRouteMapData } from "../../slices/route-map/routeMapSlice";
import { pickupShipmentSelector, submitCompletePickup } from "../../slices/stop-details/pickupShipmentSlice";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../app/route/RoutesConfig";
import { useSelector } from "react-redux";
import { LabelModes } from "../../components/molecules/labeled-inputs/labeledInput";
import LabeledMaskInput from "../../components/molecules/labeled-inputs/labeled-mask-input/labeledMaskInput";
import { Formik, FormikProps, Form } from "formik";
import { PickupDocument, PickupDocumentType, SubmitPickupRequestSchema } from "../../app/data/stop-details/models";
import { pickupsSelector } from "../../slices/pickups/pickupsSlice";
import { useQuery } from "@tanstack/react-query";
import { apiQueryAdapter } from "../../app/data/apiQueryAdapter";
import { UploadBolDocument } from "./complete-pickup-steps/uploadBol";

export interface CompletePickupModalProps {
  manifestNumber: number;
  show: boolean;
  onClose: () => void;
  pickupNumber: string;
  stopNumber: number;
}

const initialFormValues = {
  probill: ""
}


export const CompletePickupModal: React.FC<CompletePickupModalProps> = (props) => {
  const [photos, setPhotos] = useState<any[]>([]);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const pickupShipmentState = useSelector(pickupShipmentSelector);
  const stopDetailsState = useSelector(stopDetailsSelector);
  const pickupsState = useSelector(pickupsSelector);
  const [probill, setProbill] = useState("");

  const documentsQuery = useQuery({
    queryKey: ["pickup_docs"],
    queryFn: () => apiQueryAdapter.query<PickupDocument[]>(`/pickups/${props.pickupNumber}/documents`, "GET"),
    refetchInterval: 5000
  })

  const currentStopDetails = props.manifestNumber
    ? (stopDetailsState.details ? stopDetailsState.details[props.stopNumber - 1] : null)
    : ((pickupsState.pickups?.length > 0) ? pickupsState.pickups[props.stopNumber - 1] : null);

  const onSuccess = () => {
    toast.info("The pickup has been completed!");
    if (props.manifestNumber) {
      dispatch(getRouteSummary(props.manifestNumber));
      dispatch(getAllStopsDetails(props.manifestNumber));
      dispatch(getRouteMapData(props.manifestNumber));
    }
    props.onClose();
    navigate(props.manifestNumber ? `/${props.manifestNumber}${ROUTES.route}` : "/");
  }
  
  const onOffline = () => {
    toast.info("Pickup will be completed when internet is back online!")
    updateCacheForCompletedPickup(props.manifestNumber, props.stopNumber);
    if (props.manifestNumber) {
      dispatch(getRouteSummary(props.manifestNumber));
      dispatch(getAllStopsDetails(props.manifestNumber));
      dispatch(getRouteMapData(props.manifestNumber));
    }
    props.onClose();
    navigate(props.manifestNumber ? `/${props.manifestNumber}${ROUTES.route}` : "");
  }

  const onSubmit = () => {
    const fd = new FormData();
    const actualPhotos = photos.filter((obj: any) => !obj.deleted);
    for (let photo of actualPhotos) {
      fd.append("files", photo);
    }

    const preparedData = {
      pickupNumber: props.pickupNumber,
      probillNumber: probill,
      documents: actualPhotos.map((photo) => ({type: PickupDocumentType.BOL_PHOTO}))
    };

    fd.append("data", JSON.stringify(preparedData));
    dispatch(submitCompletePickup(fd,
      onSuccess,
      onOffline));
  }
  
  return (
    <SlideInModal mods={{ "full-screen": true }} show={props.show} title={`Complete pickup: ${props.pickupNumber}`} onClose={props.onClose}>
      <div className="xgs-complete-pickup">
        <Formik validationSchema={SubmitPickupRequestSchema} onSubmit={() => onSubmit()} initialValues={initialFormValues}>
          {(formProps: FormikProps<{probill: string}>) => (
            <Form>
              <LabeledMaskInput
                name="probill"
                value={probill}
                onValueChange={(e) => {
                  setProbill(e);
                  formProps.setFieldValue("probill", e);
                }}
                format="########"
                className="xgs-complete-pickup__probill-input"
                label="Probill number:"
                labelMode={LabelModes.column}
                inputMode="numeric"
              />
              <UploadBolDocument photos={photos} setPhotos={setPhotos} pickupNumber={props.pickupNumber}/>
              {currentStopDetails?.specialInstructions && (
                <p className="xgs-complete-pickup__special">
                  <b>Special instructions: </b>
                  {currentStopDetails.specialInstructions}
                </p>
              )}
              <Button 
                spinner={pickupShipmentState.requestStarted} 
                className="xgs-complete-pickup__submit" 
                theme={ButtonThemes.blue}
                disabled={!(photos.length || !!documentsQuery.data?.length)}
              >
                Submit
              </Button>
            </Form>
          )}
        </Formik>
      </div>
    </SlideInModal>
  )
}
