import { IState } from "..";
import { createSlice } from "@reduxjs/toolkit";
import { AppThunk } from "../../app/store";
import { initialPickupShipmentState } from "./pickupShipmentState";
import PickupShipmentService from "../../app/data/stop-details/pickupShipmentService";
import { toast } from "react-toastify";

const pickupShipmentService = PickupShipmentService.getInstance();

export const pickupShipmentSlice = createSlice({
  name: "pickupShipment",
  initialState: initialPickupShipmentState,
  reducers: {
    resetPickupShipmentState: (state) => initialPickupShipmentState,
    requestStarted: (state, { payload }) => {
      state.requestStarted = true;
      state.requestFailed = false;
      state.requestSucceed = false;
      state.requestFailReason = null;
    },
    requestFailed: (state, { payload }) => {
      state.requestStarted = false;
      state.requestFailed = true;
      state.requestSucceed = false;
      state.requestFailReason = payload.error;
    },
    requestSucceed: (state) => {
      state.requestStarted = false;
      state.requestFailed = false;
      state.requestSucceed = true;
      state.requestFailReason = null;
    }
  }
});

export const {
  resetPickupShipmentState,
  requestStarted,
  requestFailed,
  requestSucceed
} = pickupShipmentSlice.actions;

export const pickupShipmentSelector = (state: IState) => {
  return state.pickupShipment;
};

export const submitCompletePickup = (
  fd: FormData,
  onSuccess: () => void,
  onOffline: () => void
  ): AppThunk => async (dispatch) => {
  dispatch(requestStarted("COMPELTE_PICKUP"));
  const response = await pickupShipmentService.completePickup(fd);
  if (navigator.onLine) {
    if (response.ok()) {
      dispatch(requestSucceed());
      onSuccess();
    } else {
      dispatch(requestFailed(response.getError()));
      response?.getError && toast.info(response.getError() as string || "Something went wrong")
    }
  } else {
    requestSucceed();
    onOffline();
  }
}

const pickupShipmentReducer = pickupShipmentSlice.reducer;
export default pickupShipmentReducer;
