import React, { useRef, useState } from "react";
import { useDropzone } from "react-dropzone";
import { ACCEPTED_FORMATS, MAX_SIZE } from "../../exceptions/constants";
import bol_doc_img from "../../../images/bol_doc.svg";
import zoom_img from "../../../images/zoom.svg";
import bol_camera_img from "../../../images/bol_camera.svg";
import "./uploadBol.scss";
import Button, { ButtonThemes } from "../../../components/button/button";
import { useQuery } from "@tanstack/react-query";
import { apiQueryAdapter } from "../../../app/data/apiQueryAdapter";
import { PickupDocument, PickupDocumentType } from "../../../app/data/stop-details/models";
import { useSelector } from "react-redux";
import { commonSelector } from "../../../slices/common/commonSlice";
import { useDispatch } from "react-redux";
import { setImageForPreview } from "../../../slices/preview-image/previewImageSlice";

const MAX_PHOTOS = 5;

export interface UploadBolProps {
  pickupNumber: string;
  photos: any[];
  setPhotos: (val: any[]) => void;
}

export const UploadBolDocument: React.FC<UploadBolProps> = ({ photos, setPhotos,...props}) => {
  const [fileError, setFileError] = useState("");
  const currentUploadType = useRef<"BOL_DOCUMENT" | "BOL_PHOTO">("BOL_PHOTO")
  const commonState = useSelector(commonSelector);
  const [appInstalled, setAppInstalled] = useState(true);
  const dispatch = useDispatch();

  const offline = commonState.offline;

  const documentsQuery = useQuery({
    queryKey: ["pickup_docs"],
    queryFn: () => apiQueryAdapter.query<PickupDocument[]>(`/pickups/${props.pickupNumber}/documents`, "GET"),
    refetchInterval: 5000
  })

  function openDocumentScannerApp(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    var appLink = `xgsdriver://?token=${localStorage.getItem("xgs-driver-access-token")}&pickupId=${props.pickupNumber}`;
    e.stopPropagation();

    currentUploadType.current = PickupDocumentType.BOL_DOCUMENT;

    
    var timeout = setTimeout(function () {
      setAppInstalled(false)
      window.removeEventListener("blur", appInstalledTimeout)
    }, 500);
    
    const appInstalledTimeout = () => {
      setAppInstalled(true);
      clearTimeout(timeout);
    }
    window.location.href = appLink;
  
    window.addEventListener("blur", appInstalledTimeout);
  }

  const {
      getRootProps,
      getInputProps
    } = useDropzone({
      accept: ACCEPTED_FORMATS,
      maxSize: MAX_SIZE * 1048576,
      maxFiles: MAX_PHOTOS - photos.length >= 0 ? MAX_PHOTOS - photos.length : 0,
      disabled: MAX_PHOTOS - photos.length <= 0,
      onDrop: async (acceptedFiles, fileRejections) => {
        setFileError("");
        if (fileRejections?.length > 0) {
          fileRejections[0].errors.forEach((err) => {
            if (err.code === "file-too-large") {
              setFileError(`Files no larger than ${MAX_SIZE} MB are allowed!`);
            }
            if (err.code === "file-invalid-type") {
              setFileError("Only images of certain formats (JPEG, PNG, WebP, GIF and BMP) are allowed!");
            }
            if (err.code === "too-many-files") {
              setFileError(`Maximum ${MAX_PHOTOS} photos are allowed!`);
            }
          });
        }

        setPhotos([...photos, ...acceptedFiles.map(file => Object.assign(file, {
          preview: URL.createObjectURL(file),
          deleted: false,
          documentType: PickupDocumentType.BOL_PHOTO
        }))]);
      }
    });

  const removePhoto = (e: any, i: number) => {
    e.stopPropagation()
    let newArr = [...photos];
    setPhotos(newArr.filter((_, idx) => i !== idx));
  };

  // const hasBolDocument = documentsQuery.data && documentsQuery.data.some(doc => doc.type === PickupDocumentType.BOL_DOCUMENT);

  const onClickThumbnail = (imageUrl: string) => {
    dispatch(setImageForPreview(imageUrl));
  }

  const namedDocuments = documentsQuery.data ? documentsQuery.data.map((doc, idx) => {
    return Object.assign(doc, {
      preview: doc.storageUrl,
      photoName: `Scan ${String(idx+1).padStart(2, '0')}`
    })
  }) : []

  const namedPhotos = photos.map((photo, idx) => Object.assign(photo, {
    photoName:  `Photo ${String(idx+1).padStart(2, '0')}`
  }))

  const showDocumentScannerButton = !offline && appInstalled;

  return (
    <div className="xgs-upload-bol">
      <div className="xgs-upload-bol__title">Upload BOL Images</div>
      <div className="xgs-upload-bol__notes">
        Please upload two types of images: scanned copies of the Bill of Lading pages and photos of the freight items taken with the camera on this device.
        {!appInstalled && <div style={{ marginTop: 4 }}>Document scanner app is not installed. Please contact IT services to install it on your device.</div>}
      </div>
      <div className="xgs-upload-bol__upload-buttons">
        {showDocumentScannerButton && <Button
          className="xgs-upload-bol__upload-buttons__scan"
          type="button" 
          disabled={MAX_PHOTOS - (documentsQuery.data?.length || 0) <= 0} 
          onClick={openDocumentScannerApp} 
          theme={ButtonThemes.blue}
          spinner={documentsQuery.isLoading}
        > 
          <img src={bol_doc_img} alt="doc icon"/> &nbsp; BOL Scans
        </Button>}
        {MAX_PHOTOS - photos.length > 0 && <div {...getRootProps()}>
          <input {...getInputProps()}/>
          <div className="xgs-upload-bol__dropzone__buttons">
            <Button 
              type="button" 
              disabled={MAX_PHOTOS - photos.length <= 0} 
              theme={ButtonThemes.gray}
              className="xgs-upload-bol__upload-buttons__scan"
            >
              <img src={bol_camera_img} alt="doc icon"/>
              &nbsp;&nbsp;
              Item Photo
            </Button>
          </div>
        </div>}
      </div>
      {fileError && (
        <span className="xgs-upload-bol__dropzone__area__error">
          {fileError}
        </span>
      )}
      <div className="xgs-upload-bol__photos">
        <div className="xgs-upload-bol__photos__heading">
          Scans
        </div>
        <div className="xgs-upload-bol__photos__container">
          {
            namedDocuments.map((photo) => (
              <div key={photo.photoName} className="xgs-upload-bol__photos__item">
                <div className="xgs-upload-bol__photos__item__name">{photo.photoName}</div>
                <img onClick={() => onClickThumbnail(photo.preview)} className="xgs-upload-bol__photos__item__thumbnail" src={photo.preview} alt="preview"/>
                <img onClick={() => onClickThumbnail(photo.preview)} className="xgs-upload-bol__photos__item__zoom" src={zoom_img} alt="zoom" />
              </div>
            ))
          }
        </div>
        {!namedDocuments.length && <div className="xgs-upload-bol__photos__none-selected">No documents selected.</div>}
      </div>

      <div className="xgs-upload-bol__photos">
        <div className="xgs-upload-bol__photos__heading">
          Photos
        </div>
        <div className="xgs-upload-bol__photos__container">
          {
            namedPhotos.map((photo, idx) => (
              <div key={photo.photoName} className="xgs-upload-bol__photos__item">
                <div className="xgs-upload-bol__photos__item__name">{photo.photoName}</div>
                <img onClick={() => onClickThumbnail(photo.preview)} className="xgs-upload-bol__photos__item__thumbnail" src={photo.preview} alt="preview"/>
                <img onClick={() => onClickThumbnail(photo.preview)} className="xgs-upload-bol__photos__item__zoom" src={zoom_img} alt="zoom" />
                <Button type="button" onClick={(e) => removePhoto(e, idx)} theme={ButtonThemes.gray}>Delete</Button>
              </div>
            ))
          }
        </div>
        {!namedPhotos.length && <div className="xgs-upload-bol__photos__none-selected">No images selected.</div>}
      </div>
  </div>
  )
}
